import React, { useEffect, useMemo, useRef } from 'react'
import { useQuery } from "@apollo/client";
import { isEmpty } from "lodash";
import { css, useTheme } from "@emotion/react";

import useBreakpoint from "core/hooks/useBreakpoint";
import useSetState from "core/hooks/useSetState";
import { getTierCategories } from "core/includes/queries";

import FeatureWrapper from "elements/Components/SubscriptionPlan/components/Features/FeatureWrapper";
import FeatureArea from "elements/Components/SubscriptionPlan/components/Features/FeatureArea";
import FeatureBox from "elements/Components/SubscriptionPlan/components/Features/FeatureBox";
import Loading from "core/Components/Utils/Loading";
import SubscriptionOptions from "elements/Components/SubscriptionList/components/SubscriptionOptions";
import FeatureColourBoxWrapper from "elements/Components/SubscriptionPlan/components/Features/FeatureColourBoxWrapper";
import FeatureColourBox from "elements/Components/SubscriptionPlan/components/Features/FeatureColourBox";
import SubscriptionTitles from "elements/Components/SubscriptionList/components/SubscriptionTitles";

type Props = {
    data: SubscriptionList,
}

type DefaultState = {
    extraPaddingTop: number,
    heights: {},
};

const INITIAL_STATE = {
    extraPaddingTop: 0,
    heights: { main: 57 },
};

const SubscriptionList: React.FC<Props> = ({ data }) => {
    const theme = useTheme();
    const contentRef = useRef<any>({});
    const topTitleRef = useRef<any>();
    const { minWidth } = useBreakpoint();
    const [state, setState] = useSetState<DefaultState>(INITIAL_STATE);

    const { data: tierCategoriesData, loading: tierCategoriesLoading } = useQuery(getTierCategories, {
        variables: { parentId: data.id },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "network-only",
    });

    const [
        featurePaddingTop,      // padding-top of the FeatureWrapper
        featureBoxWidth,        // The box width of the Feature
        otherBoxWidth,          // The box width of the Personal & the Education
        featureInnerBoxWidth,   // The text area width of the Feature
        otherInnerBoxWidth,     // The text area width of the Personal & the Education
        titleHeight,            // The title box height for each box
        dotMargin,              // The margin of dot Div
        gap,                    // The gap of Grid
    ] = useMemo(() => {
        switch (true) {
            case minWidth >= theme.breakpoints.sizes['3xl']:
                return [40, 405, 405, 340, 340, 57, 30, 19];
            case minWidth >= theme.breakpoints.sizes['xxl']:
                return [85, 405, 405, 340, 340, 57, 30, 30];
            case minWidth >= theme.breakpoints.sizes['xl']:
                return [85, 405, 405, 340, 340, 57, 30, 21];
            case minWidth >= theme.breakpoints.sizes['lg']:
                return [85, 395, 244, 327, 194, 50, 25, 27];
            case minWidth >= theme.breakpoints.sizes['md']:
                return [85, 332, 160, 260, 122, 50, 20, 16];
            case minWidth >= theme.breakpoints.sizes['sm']:
                return [85, 280, 194, 240, 174, 50, 15, 6];
            default:
                return [85, 186, 148, 155, 115, 48, 10, 6];
        }
    }, [minWidth, theme.breakpoints.sizes]);


    useEffect(() => {
        if (!isEmpty(contentRef.current)) {
            const heights: any = {};
            Object.keys(contentRef.current).forEach((key: string) => {
                const keyToUse = key as keyof typeof contentRef.current;
                if (!heights[key]) {
                    heights[key] = contentRef.current[keyToUse].offsetHeight;
                }
            })
            setState({ heights });
        }
    }, [setState, minWidth, tierCategoriesData]);

    useEffect(() => {
        if (minWidth >= theme.breakpoints.sizes['md']) {
            setState({ extraPaddingTop: 0 });
        } else if (topTitleRef.current) {
            setState({ extraPaddingTop: topTitleRef.current.offsetHeight });
        }
    }, [setState, topTitleRef, minWidth, theme.breakpoints.sizes]);

    const tierCategories = tierCategoriesData?.tierCategories;

    const addRef = (ref: any, key: string) => {
        if (ref) {
            contentRef.current[key] = ref;
        }
    }

    const singleColumnStyle = css`
        ${theme.breakpoints.up('md')} {
            display: none;
        }
    `;

    const multipleColumnStyle = css`
        ${theme.breakpoints.down('sm')} {
            display: none;
        }
    `;

    const tierCategoryLabelStyle = (hasTopMargin: boolean) => css`
        text-align: center;
        font-size: 40px;
        font-weight: ${theme.fonts.weights.bold};
        line-height: 45px;
        color: ${theme.colours.curiousBlue};
        margin-bottom: 20px;

        ${hasTopMargin && css`
            margin-top: 20px;
        `}
    `;

    return (
        <FeatureWrapper paddingTop={featurePaddingTop}>
            {tierCategoriesLoading ? (
                <Loading />
            ) : (
                <FeatureArea>
                    <FeatureBox
                        boxRef={(ref: any) => addRef(ref, 'main')}
                        css={multipleColumnStyle}
                    >
                        <SubscriptionTitles
                            tierCategories={tierCategories}
                            name={data?.name}
                            otherBoxWidth={otherBoxWidth}
                            featureBoxWidth={featureBoxWidth}
                            featureInnerBoxWidth={featureInnerBoxWidth}
                            titleHeight={titleHeight}
                            otherInnerBoxWidth={otherInnerBoxWidth}
                            gap={gap}
                        />
                        <SubscriptionOptions
                            options={data?.options}
                            tierCategories={tierCategories}
                            featureBoxWidth={featureBoxWidth}
                            otherBoxWidth={otherBoxWidth}
                            dotMargin={dotMargin}
                            featureInnerBoxWidth={featureInnerBoxWidth}
                            gap={gap}
                            otherInnerBoxWidth={otherInnerBoxWidth}
                        />
                    </FeatureBox>
                    <div css={singleColumnStyle}>
                        {tierCategories?.map((tierCategory: TierCategory, index: number) => {
                            const options = data?.options
                                .filter((option: SubscriptionOption) => (
                                    option.tiers.find((tier: SubscriptionTier) => tier.category.id === tierCategory.id)
                                ));
                            return (
                                <React.Fragment key={`fbtc-${tierCategory.id}`}>
                                    <div css={tierCategoryLabelStyle(index !== 0)}>{tierCategory.name}</div>
                                    <FeatureBox
                                        boxRef={(ref: any) => addRef(ref, tierCategory.id)}
                                    >
                                        <SubscriptionOptions
                                            options={options}
                                            tierCategories={[tierCategory]}
                                            featureBoxWidth={featureBoxWidth}
                                            otherBoxWidth={otherBoxWidth}
                                            dotMargin={dotMargin}
                                            featureInnerBoxWidth={featureInnerBoxWidth}
                                            gap={gap}
                                            otherInnerBoxWidth={otherInnerBoxWidth}
                                        />
                                    </FeatureBox>
                                </React.Fragment>
                            )
                        })}
                    </div>
                    <FeatureColourBoxWrapper
                        extraPaddingTop={25}
                        height={state.heights['main' as keyof typeof state.heights]}
                        gap={gap}
                        featureBoxWidth={featureBoxWidth}
                        otherBoxWidth={otherBoxWidth}
                        css={multipleColumnStyle}
                    >
                        <FeatureColourBox width={featureBoxWidth} isFeatureBox />
                        {tierCategories?.map((tierCategory: TierCategory) => (
                            <FeatureColourBox
                                key={`fcb-${tierCategory.id}`}
                                width={otherBoxWidth}
                                backgroundColour={tierCategory.backgroundColour}
                            />
                        ))}
                    </FeatureColourBoxWrapper>
                    <div css={singleColumnStyle}>
                        {tierCategories?.map((tierCategory: TierCategory, index: number) => {
                            const key = tierCategory.id as keyof typeof state.heights;
                            const previousHeight = index > 0 ?
                                state.heights[tierCategories?.[index - 1]?.id as keyof typeof state.heights] + 105 :
                                0;
                            return (
                                <FeatureColourBoxWrapper
                                    extraPaddingTop={30 + previousHeight}
                                    height={state.heights[key]}
                                    gap={gap}
                                    featureBoxWidth={featureBoxWidth}
                                    otherBoxWidth={otherBoxWidth}
                                    key={`fcbw-${tierCategory.id}`}
                                >
                                    <FeatureColourBox width={featureBoxWidth} isFeatureBox />
                                    <FeatureColourBox
                                        key={`fcb-${tierCategory.id}`}
                                        width={otherBoxWidth}
                                        backgroundColour={tierCategory.backgroundColour}
                                    />
                                </FeatureColourBoxWrapper>
                            )
                        })}
                    </div>
                </FeatureArea>
            )}
        </FeatureWrapper>
    );
}

export default SubscriptionList;